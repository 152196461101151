<template>
    <div>
        <div class="container py-5 my-0 my-sm-5 px-4">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="row align-items-center mb-0 pb-5">
                        <div class="col-6 col-sm-6 col-md-6 text-left">
                            <div class="logo-wrapper"><img src="@/assets/img/bau-logo-for-light.png"/>sada</div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 text-right">
                            <h4>{{$t('graduate_application_form').toUpper()}}</h4>
                            <b-button v-if="stateData.personalInfo" @click="logout" variant="danger" class="float-right" size="sm">{{$t('logout').toUpper()}}</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <h6>{{$t('welcome_to')+' '+stateData.personalInfo.name+' '+stateData.personalInfo.surname}}</h6>
                </div>
                <div class="col-12">
                    <hr/>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <b-form-group>
                                <multiselect :options="preferenceOptions"
                                             v-model="selectedPreference"
                                             :placeholder="$t('program_information')"
                                             label="text"
                                             track-by="value"
                                             :multiple="false"
                                             :select-label="''"
                                             :selected-label="''"
                                             :deselect-label="''"
                                             @input="loadAppInfo"
                                ></multiselect>
                                </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <b-button block variant="primary" @click="$router.push({name: 'graduateApplicationForm'})">{{$t('graduate_application_form_update_btn')}}</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <h6>{{ $t('application_information') }}</h6>
                </div>
                <div class="col-12">
                    <hr/>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 col-lg-4">
                            <b-form-group :label="$t('application_status')">
                                {{applicationInfo.applicationStatus}}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-lg-4">
                            <b-form-group :label="$t('scholarship_status')">
                                {{applicationInfo.scholarshipStatus}}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-lg-4">
                            <b-form-group :label="$t('student_status')">
                                {{applicationInfo.studentStatus}}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-lg-4">
                            <b-form-group :label="$t('program_coordinator')">
                                {{applicationInfo.programCoordinator}}
                            </b-form-group>
                        </div>
                        <div class="col-6 col-lg-4">
                            <b-form-group :label="$t('language_exam_score')">
                                {{langExamScore}}
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <h6>{{ $t('scientific_preparation_courses') }}</h6>
                </div>
                <div class="col-12">
                    <hr/>
                </div>
                <div class="col-12">
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        hover
                        striped
                        responsive
                        :items="courses"
                        :fields="courseFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                    </b-table>
                </div>
                <div class="col-12 mt-4">
                    <h6>{{ $t('documents') }}</h6>
                </div>
                <div class="col-12">
                    <hr/>
                </div>
                <div class="col-12">
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        hover
                        striped
                        responsive
                        :items="documents"
                        :fields="documentFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                    </b-table>
                </div>
                <div class="col-12" v-if="documentCounter > 8">
                    {{ $t('ph_programs_info') }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GraduateApplicationService from "@/services/GraduateApplicationService";

export default {
    components: {

    },
    metaInfo() {
        return {
            title: this.$t('graduate_application_form')
        }
    },
    data() {
        return {
            stateData: {},
            preferenceOptions: [],
            selectedPreference: null,
            courses: [],
            courseFields: [
                {
                    key: 'code',
                    label: this.$t('course_code'),
                },
                {
                    key: 'name',
                    label: this.$t('course_name'),
                },
            ],
            documents: [],
            documentFields: [
                {
                    key: 'name',
                    label: this.$t('document_type_name'),
                },
                {
                    key: 'acceptance',
                    label: this.$t('accepttance_status'),
                },
                {
                    key: 'desc',
                    label: this.$t('explanation'),
                },
            ],

            applicationInfo: {
                applicationStatus: '-',
                scholarshipStatus: '-',
                studentStatus: '-',
                programCoordinator:'-'
            },
            langExamScore: '-',
            serviceData: {},
            documentCounter: 0,
        }
    },
    created() {
        this.$store.dispatch("graduateApplication/initKvkk");
        this.stateData = {
            kvkk: this.$store.getters['graduateApplication/getKvkk'],
            tabIndex: this.$store.getters['graduateApplication/getLbfTab'],
            personalInfo: this.$store.getters['graduateApplication/getLbfPersonalInfo'] != null ? this.$store.getters['graduateApplication/getLbfPersonalInfo'] : false
        }


            this.getAll()


    },
    watch: {

    },
    methods: {
        getAll(){
            let formData = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin
            }

            GraduateApplicationService.show(formData).then(response => {
                this.serviceData = response.data.data
            }).then(() => {
                this.getPreferences()
                this.getDocuments()
            })
        },

        getPreferences() {
            this.serviceData.preferences.forEach((itm) => {
                this.preferenceOptions.push({
                    text: itm.program.name,
                    value: itm.id
                })
            })
            if(this.serviceData.exams){
                if(this.serviceData.exams.bau_lang_sufficiency) {
                    this.langExamScore = this.serviceData.exams.bau_lang_sufficiency.score
                }
            }

        },

        getDocuments(){
            let otherDocuments = []
            if(this.serviceData.otherDocuments){
                this.serviceData.otherDocuments.forEach(itm => {
                    otherDocuments.push({
                        name: this.$t('other_documents')+' - '+itm.file_name,
                        acceptance: itm.status_name,
                        desc: itm.description
                    })
                })
            }

            if(this.serviceData.documents){
                Object.keys(this.serviceData.documents).forEach(index => {
                    if(index == 'other_documents'){
                        if(otherDocuments.length < 1){
                            this.documents.push({
                                name: this.serviceData.documents[index].name,
                                acceptance: this.serviceData.documents[index].status_name,
                                desc: this.serviceData.documents[index].description
                            })
                        }
                    } else {
                        this.documents.push({
                            name: ['thesis_project_documents', 'graduate_transcript', 'post_graduate_diploma'].includes(index) ? '(*) '+this.serviceData.documents[index].name : this.serviceData.documents[index].name,
                            acceptance: this.serviceData.documents[index].status_name,
                            desc: this.serviceData.documents[index].description
                        })
                    }

                })
                this.documentCounter = this.documents.length
                this.documents = [...this.documents,...otherDocuments]
            }

        },

        loadAppInfo(record){
            this.courses = []
           this.serviceData.preferences.forEach(itm => {
               if(record.value == itm.id) {
                   let scholarships = ''
                   if(itm.scholarships){
                       Object.values(itm.scholarships).forEach(val => {
                           scholarships += '- '+val.name+'(%'+val.rate+')'
                       })
                   }

                   this.applicationInfo = {
                           applicationStatus: itm.status_name != null ? itm.status_name : '-',
                           scholarshipStatus: scholarships != '' ? scholarships : '-',
                           studentStatus: itm.student_status_name != null ? itm.student_status_name : '-',
                           programCoordinator: itm.program.head_of_program_name != null ? itm.program.head_of_program_name : '-',
                   }
                    if(itm.scientific_preparation_courses != null){
                        Object.keys(itm.scientific_preparation_courses).forEach(index => {
                            this.courses.push({
                                code: index,
                                name: itm.scientific_preparation_courses[index].name
                            })
                        })
                    }

               }
           })
        },

        logout(){
            this.$store.dispatch("graduateApplication/logout");
            this.$router.push({name: 'graduateApplicationLogin'})
        }
    }
};
</script>

